import React, { useState, useContext, useEffect } from 'react';

import lookAndFeelContext from '../../../context/LookAndFeelContext';
import languageContext from '../../../context/languageContext';
import MessageContext from '../../../context/MessageContext';

import Icons from '../../PillowSlide/atom/PT_SVG';

interface PredefinedColorType {
    _id: string;
    main: string;
    accent1: string;
    accent2: string;
    name: string;
    order: number;
    isActive: boolean;
    fontColor: string;
    key?: string;
    isCorporate?: boolean;
}

const ColorTab = ({ colorpick }: any) => {
    const {
        element,
        onElementChangge,
        activeColor,
        colorScheme,
        isPredefineColor,
        onToggleColor,
        onSaveColor,
        customColor,
        isCustomColor,
        onDeleteColor,
    }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);
    const { setMessage } = useContext(MessageContext);
    const { style = { value: [] }, colors = { value: [] } } = element;

    const initialvalue: any = {
        applied: null,
        colors: [],
        status: true,
    };

    const [mai, setmai] = useState(initialvalue);
    const [accent1, setaccent1] = useState(initialvalue);
    const [accent2, setaccent2] = useState(initialvalue);
    const [colorGroup, setColorGroup] = useState<PredefinedColorType[]>([]);
    const [activColoreGroup, setActiveColorGroup] = useState<any>({});

    const saveColorLimit = 12;
    const saveColorLimitMessage = "You can't save more than 12 colour schemes.";
    const saveSameColourMessage = 'You have already added this colour scheme.';
    // const saveColourSuccessMessage = "Colour set is saved.";
    const colourDeleteConfirmMessage = 'Are you sure you want to delete this colour scheme?';

    useEffect(() => {
        setColorGroup(colorScheme);
    }, [colorScheme]);
    useEffect(() => {
        setActiveColorGroup(activeColor);
    }, [activeColor]);

    useEffect(() => {
        if (colors.value[style.applied] !== undefined) {
            setmai(colors.value[style.applied]?.main);
            setaccent1(colors.value[style.applied]?.accent_1);
            setaccent2(colors.value[style.applied]?.accent_2);
        }
    }, [colors, style.applied]);

    const localColoPicker = (col: any, name: any, list: any) => {
        let color = col;
        // console.log(color, name, list);
        // console.log(style.applied);
        if (color === null || color === undefined) {
            color = mai.applied;
        }
        let selected = '';
        if (list.includes(color) === false) selected = 'colorpickerpillow';
        return (
            <div
                className={`flex setting__e lement__color ${selected} colorpickerpillow`}
                onClick={() => colorpick({ color, name, list })}
            >
                <span className="setting__element__colorpicker cursor-pointer" style={{ background: color }}></span>
            </div>
        );
    };

    const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>, target: string) => {
        const colorobj = {
            ...element,
            colors: {
                ...element.colors,
                value: {
                    ...element.colors.value,
                    [style.applied]: {
                        ...element.colors.value[style.applied],
                        [target]: {
                            ...element.colors.value[style.applied][target],
                            status: e.target.checked,
                        },
                    },
                },
            },
        };
        onElementChangge(colorobj.colors);
    };

    const colorSelectionFn = (col: PredefinedColorType, obj: any) => {
        onToggleColor({
            payload: {
                ...obj,
                activeColorId: col === null ? '' : col._id,
            },
            col,
        });
        if (col !== null) setActiveColorGroup(col);
    };

    const isPredefineColorFn = (flag: boolean) => {
        const colorobj = {
            ...element,
            colors: {
                ...element.colors,
                value: {
                    ...element.colors.value,
                    [style.applied]: {
                        ...element.colors.value[style.applied],
                        main: {
                            ...element.colors.value[style.applied].main,
                            applied: element.colors.value[style.applied].main.colors[0],
                        },
                        accent_1: {
                            ...element.colors.value[style.applied].main,
                            applied: element.colors.value[style.applied].main.colors[1],
                        },
                        accent_2: {
                            ...element.colors.value[style.applied].main,
                            applied: element.colors.value[style.applied].main.colors[2],
                        },
                    },
                },
            },
        };

        onElementChangge(colorobj.colors);
        // return null;

        onToggleColor({
            payload: {
                isPredefinedColor: flag,
                isCustomColor: flag,
                activeColorId: activColoreGroup._id,
            },
            col: {
                main: mai.colors[0],
                accent1: mai.colors[1],
                accent2: mai.colors[2],
            },
        });
    };

    const saveUsercolor = () => {
        // CHECK SAVED COLOUR LIMIT
        // SHOW MESSAGE BASE ON CONDITION
        let notValidLength = false;
        if (customColor.length >= saveColorLimit) {
            notValidLength = true;
            // setMessage({
            //     display: true,
            //     message: saveColorLimitMessage,
            //     fn: () => {},
            // })
            // return null;
        }

        // CHECK COLOUR SET ALREADY EXIST OR NOT
        let match = false;
        customColor.map((s: any) => {
            const m = mai.applied,
                o = accent1.applied !== '' ? accent1.applied : mai.applied,
                t = accent2.applied !== '' ? accent2.applied : mai.applied;
            if (s.main === m && s.accent1 === o && s.accent2 === t) {
                match = true;
            }
            return null;
        });

        if (isPredefineColor === true || isCustomColor === true) {
            match = true;
        }
        let msg = '';
        if (notValidLength) {
            msg = saveColorLimitMessage;
        }
        if (match) {
            if (msg === '') msg = saveSameColourMessage;
            else msg += '<br />' + saveSameColourMessage;
        }

        // STOP ADDING COLOURS IF ALREADY EXIST
        // SHOW MESSAGE
        if (match) {
            setMessage((prev: any) => ({
                ...prev,
                display: true,
                message: msg,
                fn: () => {
                    console.log();
                },
            }));
            return null;
        }

        onSaveColor({
            main: mai.applied,
            accent1: accent1.applied !== '' ? accent1.applied : mai.applied,
            accent2: accent2.applied !== '' ? accent2.applied : mai.applied,
        });
    };

    const removeUsercolor = (color: any) => {
        setMessage((prev: any) => ({
            ...prev,
            display: true,
            type: 'confirm',
            message: colourDeleteConfirmMessage,
            fn: () => {
                onDeleteColor(color);
                // setSaveColor((prev: any) => (prev.filter((p: any) => p._id !== color._id)));
            },
        }));
        return null;
    };

    // const radioIcon = (flag: boolean) => flag ? <Icons.RadioActive /> : <Icons.RadioNormal />

    return (
        <div>
            {/* SELECTED COLOR DISPLAY */}
            <ul className="list-style-none mb20">
                <li>
                    {isPredefineColor === false && isCustomColor === false ? (
                        <div className="flex mb15">
                            <div className="mainColor fz12 text-lowercase-first">
                                <label htmlFor="main">{language['main']}</label>
                                {localColoPicker(mai?.applied, 'main', mai?.colors)}
                            </div>
                            <div className="flex1 textalign-right fz12 text-lowercase-first">
                                <label htmlFor="main">{language['pillow_accent_colour']}</label>

                                <div className="flex flex-row-reverse">
                                    {localColoPicker(accent2?.applied, 'accent_2', accent2?.colors)}
                                    {localColoPicker(accent1?.applied, 'accent_1', accent1?.colors)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex mb15">
                            <div className="mainColor fz12 text-lowercase-first">
                                <label htmlFor="main">{language['main']}</label>
                                <div className={`flex setting__e lement__color`}>
                                    <span
                                        className="setting__element__colorpicker"
                                        data-main={activColoreGroup?.main}
                                        style={{ background: activColoreGroup?.main }}
                                    ></span>
                                </div>
                            </div>
                            <div className="flex1 textalign-right fz12 text-lowercase-first">
                                <label htmlFor="main">{language['pillow_accent_colour']}</label>

                                <div className="flex flex-row-reverse">
                                    <div className={`flex colorpickerpillow`}>
                                        <span
                                            className="setting__element__colorpicker"
                                            data-accent2={activColoreGroup?.accent2}
                                            style={{ background: activColoreGroup?.accent2 }}
                                        ></span>
                                    </div>
                                    <div className={`flex colorpickerpillow`}>
                                        <span
                                            className="setting__element__colorpicker"
                                            data-accent1={activColoreGroup?.accent1}
                                            style={{ background: activColoreGroup?.accent1 }}
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {
                        <button
                            onClick={saveUsercolor}
                            className="fz12 mb15 text-btn fw-325 gotham-book saveColourSchemeBtn"
                        >
                            <Icons.ArrowIcon />
                            Save colour scheme
                        </button>
                    }

                    <div className={'flex justify-space-between pt10 mb10'}>
                        <label className="fz12 text-regular_small text-black flex align-center">
                            {language['accent_1'] !== undefined ? language['accent_1'] : 'Accent 1'}
                        </label>
                        <label className="cc_switch">
                            <input
                                type="checkbox"
                                checked={accent1?.status}
                                name="accent1"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCheck(e, 'accent_1')}
                            />
                            <span className="slider_pillow cursor-pointer round"></span>
                        </label>
                    </div>

                    <div className={'flex justify-space-between pb20 bottom-dash'}>
                        <label className="fz12 text-black flex align-center">
                            {language['accent_2'] !== undefined ? language['accent_2'] : 'Accent 2'}
                        </label>
                        <label className="cc_switch">
                            <input
                                type="checkbox"
                                checked={accent2?.status}
                                name="accent2"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCheck(e, 'accent_2')}
                            />
                            <span className="slider_pillow cursor-pointer round"></span>
                        </label>
                    </div>
                </li>
            </ul>

            <div className="mt20">
                <div className="mb20">
                    <h3 className="gotham-medium fz16 mb15 fw-350 line-height_16">Logo colours</h3>
                    <span
                        className={`flex align-center mb1f2 mr12 colorScheme-item ${isCustomColor === false && isPredefineColor === false ? 'active' : ''}`}
                    >
                        <div
                            className={`flex justify-space-between colorScheme-item-color ${isCustomColor === true || isPredefineColor === true ? 'cursor-pointer' : ''}`}
                            onClick={() => {
                                if (isCustomColor === true || isPredefineColor === true) {
                                    isPredefineColorFn(false);
                                }
                                // isCustomColor(false);
                                // colorSelectionFn(null, {
                                //     isPredefineColor: false,
                                //     isCustomColor: false,
                                // })
                            }}
                        >
                            <span style={{ background: `${mai.colors[0]}` }}></span>
                            <span
                                style={{ background: `${mai.colors[1] !== undefined ? mai.colors[1] : mai.colors[0]}` }}
                            ></span>
                            <span
                                style={{ background: `${mai.colors[2] !== undefined ? mai.colors[2] : mai.colors[0]}` }}
                            ></span>
                        </div>
                    </span>
                </div>

                <h3 className="gotham-medium fz16 mb15 fw-350 line-height_16">Saved colour schemes</h3>
                {customColor.length > 0 ? (
                    <div className="flex flex-wrap colorScheme mb8">
                        {customColor.slice(0, saveColorLimit).map((col: PredefinedColorType) => {
                            return (
                                <span
                                    key={col._id}
                                    className={`flex align-center mb12 mr12 color-deletable colorScheme-item ${activColoreGroup._id === col._id && isCustomColor === true ? 'active' : ''}`}
                                    role="button"
                                    onClick={(event: any) => {
                                        if (event.target.tagName !== 'SPAN') return null;
                                        if (isCustomColor === false || activColoreGroup._id !== col._id) {
                                            colorSelectionFn(col, {
                                                isPredefinedColor: false,
                                                isCustomColor: true,
                                            });
                                        }
                                    }}
                                >
                                    <div
                                        className={`flex justify-space-between ${activColoreGroup._id !== col._id || isCustomColor === false ? 'cursor-pointer' : ''} colorScheme-item-color`}
                                    >
                                        <span style={{ background: col.main }} />
                                        <span style={{ background: col.accent1 }} />
                                        <span style={{ background: col.accent2 }} />
                                    </div>
                                    {col.isCorporate !== true && (
                                        <div
                                            className="color-delete-btn cursor-pointer"
                                            onClick={() => {
                                                removeUsercolor(col);
                                            }}
                                        >
                                            <Icons.DeleteIcon />
                                        </div>
                                    )}
                                </span>
                            );
                        })}
                    </div>
                ) : (
                    <p className="gotham-book fz12 mb20">
                        You haven't saved any colour schemes yet. Click on "Save colour scheme" button to create your
                        personal colour library.
                    </p>
                )}
            </div>
            {/* CHOICE :: Your colours / Predefined Colours */}
            {/*
            <div className='colorChoice mb15 hidden'>
                <div className="gotham-book setting__element__title fw-normal text-regular_default mb20 flex align-center" onClick={() => isPredefineColorFn(false)}>
                    {radioIcon(!isPredefineColor)} {language["pillow_your_colours"]}
                </div>
                <div className="gotham-book setting__element__title fw-normal text-regular_default flex align-center" onClick={() => isPredefineColorFn(true)}>
                    {radioIcon(isPredefineColor)} {language["pillow_predefined_colours"]}
                </div>
            </div>
            */}

            <h3 className="gotham-medium fz16 mb15 fw-350 line-height_16">Predefined Colours</h3>
            {/* COLORSCHEME LIST */}
            <div className="flex flex-wrap colorScheme mb60">
                {colorGroup
                    .sort((a, b) => a.order - b.order)
                    .map((col: PredefinedColorType) => {
                        const titleTip = col.key !== undefined ? language[col.key] : col.name;
                        return (
                            <span
                                key={col._id}
                                className={`flex align-center mb12 mr12 colorScheme-item ${activColoreGroup._id === col._id && isPredefineColor === true ? 'active' : ''}`}
                                title={titleTip}
                                role="button"
                                onClick={() => {
                                    if (isPredefineColor === false || activColoreGroup._id !== col._id) {
                                        colorSelectionFn(col, {
                                            isPredefinedColor: true,
                                            isCustomColor: false,
                                        });
                                    }
                                }}
                            >
                                <div
                                    className={`flex justify-space-between ${activColoreGroup._id !== col._id || isPredefineColor === false ? 'cursor-pointer' : ''} colorScheme-item-color`}
                                >
                                    <span style={{ background: col.main }} />
                                    <span style={{ background: col.accent1 }} />
                                    <span style={{ background: col.accent2 }} />
                                </div>
                            </span>
                        );
                    })}
            </div>
        </div>
    );
};
export default ColorTab;

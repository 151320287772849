import React, { useState, useContext } from 'react';
import { UP_SVG, UPLOAD_SVG } from '../icons';
import { SIZE_ALLOW, EXTENSION_ALLOW } from '../constant';
import ImageComp from './ImageComp';
import languageContext from '../../../context/languageContext';

const Upload = ({ title, id, f, data, onDelete }: any) => {
    const language = useContext<any>(languageContext);
    let imageName, imageUrl;

    const [errorline, setErrorline] = useState<any>([]);
    const [errorList, setErrorList] = useState([
        {
            type: 'size',
            text: '- File size: <strong>max 2MB</strong>',
            err: false,
            msg: 'This file size of the selected image needs to be below 2 MB.',
        },
        {
            type: 'extension',
            text: '- File type: <strong>jpg, png or gif</strong>',
            err: false,
            msg: 'The file type of the selected image needs to be .jpg, .png or .gif',
        },
    ]);

    const validation = (targetFile: any) => {
        if (targetFile !== '') {
            const { name, size } = targetFile;
            const fileSizeKB = Math.round(size / 1024);
            let scope_error = false;
            const er: any = [];

            const newErrList = errorList.map(({ type, err, ...obj }: any) => {
                if (scope_error) {
                    return { type, ...obj, err };
                }
                if (type === 'size' && SIZE_ALLOW <= fileSizeKB) {
                    scope_error = true;
                    setErrorline([obj.msg]);
                    er.push(obj.msg);
                    return { type, ...obj, err: true };
                }
                if (type === 'extension') {
                    const extension = name.split('.').pop().toLowerCase();
                    if (!EXTENSION_ALLOW.includes(extension)) {
                        // scope_error = true;
                        er.push(obj.msg);
                        // setErrorline(obj.msg);
                        return { type, ...obj, err: true };
                    }
                }
                return { type, ...obj, err: false };
            });
            setErrorList(newErrList);
            setErrorline(er);
            return er;

            // CHECK IF THERE IS ANY ERROR TO DISPLAY ERROR MESSAGE
            // const flag = newErrList.some(({ err }: any) => {
            //     return err === true;
            // });
            // setErrFlag(flag);
            // setUploadflag(flag);
        }
    };

    const handleFile = (e: any) => {
        const { name, files } = e.target;
        const targetFile: any = files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const img: any = new Image();
            img.onload = function () {
                // setPrev(img.src);
                const er = validation(targetFile);

                if (er.length === 0) {
                    f({
                        image: targetFile,
                        type: 'coverImages',
                        subdir: name,
                        status: true,
                    });
                }
            };
            img.onerror = function () {
                // validation(targetFile, false);
                // change('');
                // setPrev(null);
                // console.log('Image uploaded must be Image.');
            };

            img.src = reader.result;
        });
        if (targetFile !== '') reader.readAsDataURL(targetFile);
        const fileInput = document.getElementById(id) as HTMLInputElement;
        fileInput.value = '';
    };

    const handleUpload = () => {
        document.getElementById(id)?.click();
    };

    const handleDelete = () => {
        onDelete({ ...data, type: id });
        setErrorline([]);
    };

    if (data === undefined) return null;
    if (id === 'front-cover') {
        imageName = data.titleImageName;
        imageUrl = data.titleImageUrl;
    }
    if (id === 'back-cover') {
        imageName = data.contactImageName;
        imageUrl = data.contactImageUrl;
    }

    return (
        <div className="mb20 line">
            <input
                type="file"
                name={id}
                id={id}
                onChange={handleFile}
                accept="image/png, image/gif, image/jpeg"
                className="hide"
            />
            <h4 className="fz16 lh16 py10 fw-350 cc-tutitle gotham-medium">{title}</h4>
            {data !== null && imageUrl !== undefined && imageUrl !== '' ? (
                <ImageComp data={{ imageName, imageUrl, errorline }} upload={handleUpload} onDelete={handleDelete} />
            ) : (
                <div className="cc-tlist">
                    <div className="flex justify-space-between align-center p20">
                        <div className="flex align-center">
                            <div className="mr20">
                                <div className="flex justify-center align-center up-icon">
                                    <UP_SVG />
                                </div>
                            </div>

                            <div>
                                <div className="fz16 lh16 fw-400 mb10 gotham-black">{language?.h_select_file}</div>
                                <p className="fz14 fw-325 lh21 gotham-book">
                                    {language?.v_file_size_2mb}
                                    <br />
                                    {language?.v_file_type_j_p_g}
                                </p>
                            </div>
                        </div>
                        <div className="dyson">
                            <button className="action primary bt-icon" onClick={handleUpload}>
                                <UPLOAD_SVG />
                                <span className="ml10">{language?.btn_upload}</span>
                            </button>
                        </div>
                    </div>
                    {errorline.length > 0 && (
                        <p className="p20 fz14 fw-325 lh21" style={{ borderTop: '1px solid #e2e2e2', color: 'red' }}>
                            {errorline}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};
export default Upload;

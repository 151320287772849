import React, { useEffect, useState, useRef, useContext } from 'react';
import { countLines } from '../../../helpers';
import boardContext from '../../../context/BoardContext';

interface PTTextareaPropType {
    title?: string;
    element: any;
    onSlideUpdate: any;
    cssStyle: any;
    editheight: any;
    style: any;
    limit: any;
    mode?: boolean;
}

export default function PTTextarea({
    title = '',
    element,
    onSlideUpdate,
    cssStyle = '',
    editheight = 0,
    style = {},
    limit = 0,
    mode = true,
}: PTTextareaPropType) {
    const { _id, draw_value = '', text_align } = element;
    // font_size, font_name, font_weight,
    const [edit, setEdit] = useState(mode);
    const [value, setValue] = useState('');

    const validLineforUserText = 4;

    const inputRef: any = useRef(null);
    const displayRef: any = useRef(null);

    const { look }: any = useContext(boardContext);
    const { font_color = '#ffffff' } = look;

    const textareaStyle = {
        // fontSize: `${font_size}px`,
        // fontWeight: `${font_weight}`,
        // fontFamily: font_name || '',
        // lineHeight: '16px',
        // background: 'none',
        color: '#fff',
        textAlign: text_align,
        ...style,
    };
    useEffect(() => {
        if (element.group === 'user_content') {
            displayRef.current.style.outline = `1px dashed ${font_color}`;
        }
    }, [element.group, font_color]);

    useEffect(() => {
        setValue(draw_value);
    }, [draw_value]);

    useEffect(() => {
        if (!edit) inputRef.current.focus();
        if (element.group === 'user_content' && displayRef.current !== null) {
            displayRef.current.style.outline = `1px dashed ${font_color}`;
        }
    }, [edit, element.group, font_color]);

    const handleMouseOver = () => {
        if (displayRef.current !== null) {
            displayRef.current.style.outline = `1px dashed ${font_color}`;
        }
    };
    const handleMouseOut = () => {
        if (element.group !== 'user_content' && displayRef.current !== null) {
            displayRef.current.style.outline = '';
        }
    };

    function toggleEdit(flag: boolean) {
        setEdit(flag);
    }

    function onLeave() {
        if (draw_value !== value) {
            onSlideUpdate({ id: _id, type: 'draw_value', value });
        }

        setTimeout(function () {
            toggleEdit(true);
        }, 100);
    }

    function focushere(e: any) {
        if (draw_value !== null) {
            e.target.selectionStart = value.length;
            e.target.selectionEnd = `${value.length}`;

            const area = inputRef.current;
            area.scrollTop = area.scrollHeight;
        }
    }

    function handleChange(e: any) {
        if (element.element_id === 'user_title') {
            if (countLines(e.target) <= 1) {
                setValue(e.target.value);
                return null;
            }
            alert("You can't add more than 1 line.");
            return null;
        }
        if (element.element_id === 'user_text') {
            // const lines = e.target.value.split("\n");
            // if (lines.length > validLineforUserText) {
            //     alert('not more than 4 lines');
            //     return null;
            // }
            if (countLines(e.target) <= validLineforUserText) {
                setValue(e.target.value);
                return null;
            }
            alert("You can't add more than 4 lines.");
            return null;
        }
        setValue(e.target.value);
    }

    function preText(text: string) {
        let subText = limit !== 0 ? text.substr(0, limit) : value;

        // Replace "\n" new line with "<br />"
        subText = subText.replace(/(\r\n|\r|\n)/g, '<br />');

        if (element.element_id === 'user_text' || element.element_id === 'user_title') {
            return subText;
        }
        if (element.element_id === 'short_description_text') {
            return text !== '' ? text : value;
        }
        if (element.element_id === 'user_text') {
            return subText;
        }
        const dotIndex = subText.lastIndexOf('.') + 1;
        return dotIndex !== undefined ? subText.slice(0, dotIndex) : subText;
    }

    return (
        <>
            {edit ? (
                <>
                    <div
                        ref={displayRef}
                        title={title}
                        role="button"
                        className={`${cssStyle} text-line-height hover--FormELe`}
                        style={textareaStyle}
                        onClick={() => {
                            toggleEdit(false);
                        }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        dangerouslySetInnerHTML={{ __html: preText(value) }}
                    />
                </>
            ) : (
                <textarea
                    ref={inputRef}
                    id="texttext"
                    name="texttext"
                    className={`${cssStyle} text-line-height hoverFormELe`}
                    style={{
                        ...textareaStyle,
                        outline: `1px dashed ${font_color}`,
                        height: `${editheight}px`,
                        background: 'none',
                    }}
                    value={value}
                    onBlur={onLeave}
                    onFocus={focushere}
                    onChange={handleChange}
                />
            )}
        </>
    );
}

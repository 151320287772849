// src/component/Title/Title.tsx
import React, { useEffect, useState } from 'react';
import iconEdit from '../../assets/icons/icon-pencil.png';
import { overviewIcon } from '../../helpers/IconList';
import './title.css';

interface TitlePropsType {
    title?: string | undefined;
    display: boolean;
    setDisplayOverview: (a: boolean) => void;
    setSettingView: () => void;
    onSave: () => void;
    setTitle: any;
    onPdfFunction?: any;
    onPptFunction?: any;
    onEmailShareflag?: any;
    onPdfFlag?: any;
    presentationType?: any;
}

const Title = ({
    title,
    setDisplayOverview,
    display,
    setSettingView,
    setTitle,
    // onPdfFunction,
    // onPptFunction,
    onEmailShareflag,
    // onPdfFlag,
    presentationType,
}: TitlePropsType) => {
    const [locTitle, setLocTitle] = useState(title);
    const [edit, setEdit] = useState<boolean>(false);

    const limit = 36;

    useEffect(() => setLocTitle(title), [title]);

    const editActivate = () => {
        setEdit(true);
        setTimeout(function () {
            const ele = document.getElementById('titleText');
            ele?.focus();
        }, 200);
    };

    const onLeave = () => {
        setTitle(locTitle);
        setEdit(false);
    };

    const focushere = (e: any) => {
        e.target.selectionStart = title?.length;
        e.target.selectionEnd = `${title?.length}`;
    };

    const handleChange = ({ target }: any) => {
        if (target.value.length <= 50) setLocTitle(target.value);
    };

    const truncateText = (text: string, lim: number) => {
        return text.length > lim ? `${text.substring(0, lim)}...` : text;
    };

    // function pdfProcess(e: any) {
    //     switch (e.target.value) {
    //         case "ppt":
    //             onPptFunction();
    //             break;
    //         case "pdf":
    //             onPdfFunction(!onPdfFlag);
    //             break;
    //         case "mailshare":
    //             onEmailShareflag();
    //             break;
    //     }
    //     setSelected("");
    // }
    // const sizeObj = {
    //   label: 'Type',
    //   name: 'type',
    //   val: 'pdf',
    //   sorts: ['pdf', 'xls', 'ppt'],
    //   change: () => {}
    //   direction: 'reverse'
    // };

    return (
        <nav id="pt-title">
            <div className="display-flex title-overview">
                <button className="btn-overview" onClick={() => setDisplayOverview(!display)}>
                    <span className="btn-text">Overview</span>
                    <img src={overviewIcon} alt="test" />
                </button>
                <h2>
                    {!edit ? (
                        <span className="flex align-center" title={locTitle}>
                            {locTitle !== undefined && truncateText(locTitle, limit)}
                            <button onClick={editActivate}>
                                <img src={`${iconEdit}`} alt="edit" />
                            </button>
                        </span>
                    ) : (
                        <input
                            type="text"
                            id="titleText"
                            name="title"
                            onFocus={focushere}
                            value={locTitle}
                            onChange={handleChange}
                            onBlur={onLeave}
                        />
                    )}
                </h2>
            </div>

            <div className="display-flex slide-filter" id={presentationType?.name}>
                {/* <button onClick={onShare}>Share</button> */}
                {/* <button onClick={onSave}>Save</button> */}
                {/* <select name="download" id="downloadSelection" value={selected} onChange={pdfProcess}>
                    <option value="">{export_label}</option>
                    <option value="pdf">PDF ({download})</option>
                    <option value="mailshare">PDF ({email_and_share})</option> */}
                {/*<option value="ppt">PPT</option>*/}
                {/* </select> */}

                <button
                    value="mailshare"
                    className="share-button"
                    onClick={() => {
                        onEmailShareflag();
                    }}
                >
                    <img
                        alt="share-icon"
                        src="data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbl8tX1NoYXJlIiBkYXRhLW5hbWU9Ikljb24gLSBTaGFyZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTIuNjUiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAzMi41MyAzNiIgZmlsbD0iIzAwNDU3QyI+CiAgPHBhdGggaWQ9IlBhdGhfMjgwNCIgZGF0YS1uYW1lPSJQYXRoIDI4MDQiIGQ9Ik0zMC4xMDgsMjcuNDQ2YTUuMjYzLDUuMjYzLDAsMCwwLTMuNTQyLDEuMzkybC0xMi44ODYtNy41YTUuOTE2LDUuOTE2LDAsMCwwLC4xNjMtMS4yNjUsNS45MTYsNS45MTYsMCwwLDAtLjE2My0xLjI2NUwyNi40MjIsMTEuMzhhNS40MSw1LjQxLDAsMSwwLTEuNzM1LTMuOTU4LDUuOTE2LDUuOTE2LDAsMCwwLC4xNjMsMS4yNjVMMTIuMTA4LDE2LjExNGE1LjQyMiw1LjQyMiwwLDEsMCwwLDcuOTE2bDEyLjg2Nyw3LjUxOGE1LjEsNS4xLDAsMCwwLS4xNDUsMS4xNzUsNS4yNzcsNS4yNzcsMCwxLDAsNS4yNzctNS4yNzdabTAtMjEuODMxQTEuODA3LDEuODA3LDAsMSwxLDI4LjMsNy40MjIsMS44MTMsMS44MTMsMCwwLDEsMzAuMTA4LDUuNjE0Wk04LjQyMiwyMS44OGExLjgwNywxLjgwNywwLDEsMSwxLjgwNy0xLjgwN0ExLjgxMywxLjgxMywwLDAsMSw4LjQyMiwyMS44OFpNMzAuMTA4LDM0LjU2NmExLjgwNywxLjgwNywwLDEsMSwxLjgwNy0xLjgwN0ExLjgxMywxLjgxMywwLDAsMSwzMC4xMDgsMzQuNTY2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTIpIi8+Cjwvc3ZnPgo="
                    />
                    <span>Share</span>
                </button>

                <button onClick={setSettingView}>
                    <span className="btn-text">Setting</span>
                    <i className="icon pfc-gear "></i>
                </button>
            </div>
        </nav>
    );
};
export default React.memo(Title);

import React from 'react';

const FrontSlide = ({ coverImageUrl }: any) => {
    return (
        <div
            className="nohoverbackground absolute h-full"
            style={{ height: '100%', width: '100%', left: '0%', top: '0%' }}
        >
            <img src={coverImageUrl} alt=""/>
        </div>
    );
};
export default FrontSlide;

// src/container/Overview/Overview.tsx
import React, { useEffect, useState, useContext, useCallback } from "react";
// import { useQuery, ReactQueryCacheProvider } from 'react-query';
import Item from "./Presentation";
import drawSingleSelect from "../../component/SingleSelect";
import Pagenation from "../../component/Pagenation";
import overContext from "../../context/OverContext";

import { getPresentationList } from "../../api/getPresentationList/getPresentationList";
import { getUserPresentations } from "../../api/getUserPresentations/getUserPresentations";
import { getRemovePresentation } from "../../api/getRemovePresentation/getRemovePresentation";
import { listType, fetchParamsType, datalistType } from "./overviewType";

const Overview = ({ baseUrl, clientID, validationToken, old_customer_id = "", type, setType, extraKey, onEditPresentationID, setFeatures }: any) => {
    const sortDefault = "desc";
    const limitDefault = 24;
    const sortByDefault = "date";

    const dat: any = useContext(overContext);

    const [isLoading, setLoading] = useState<number>(0);
    const [error, setError] = useState("");
    const [lists, setLists] = useState<listType[]>([]);
    const [localType, setlocalType] = useState("");
    const [fetchparams, setfetchparams] = useState<fetchParamsType>({
        page: 1,
        limit: localStorage.totalLimit || limitDefault,
        sort: localStorage.sortDefault || sortDefault,
        sortby: localStorage.sortBy || sortByDefault
    });
    const [total, setTotal] = useState<number>(0);
    const [datalist, setDatalist] = useState([]);
    const [language, setlanguage]: any = useState();

    useEffect(() => setlocalType(type), [type]);
    //adding localstorage for limit(dropdown)
    // localStorage.setItem("totalLimit", limit);

    useEffect(() => {
        if (localType === "" && !lists.length) {
            getPresentationList(baseUrl, clientID).then(({ status, data, translation }: any) => {
                if (status === 200) {
                    setLists(data);
                    setlanguage(translation)
                    setlocalType(type);
                    if (data.length > 0 && type) {
                        const activeType = data.filter((dat: any) => dat._id === type)[0];
                        document.title = activeType.title || 'PF Concept | Catalogue Creator - Catalogues';
                    }
                }
            });
        }
    }, [baseUrl, clientID, lists, localType, type]);

    const fetchData = useCallback(
        (baseUrl: any, client_id: any, extra: any, presentation_type: any, sortby: any, sort: any, limit: any, page: any, old_customer_id: any) => {
            setLoading(1);
            // const presentation_type = list && list._id;
            if (presentation_type) {
                const accessToken: any = validationToken(presentation_type);
                accessToken.then(({ accesskey }: any) => {
                    // const { page, limit, sort, sortby } = fetchparams;
                    getUserPresentations(baseUrl, client_id, extra, accesskey, presentation_type, page, limit, sort, sortby, old_customer_id).then(({ status, total, presentations }: any) => {
                        if (status >= 400) {
                            setError("Bad response from server");
                            return null;
                        }
                        setTotal(total);
                        setDatalist(presentations);
                        setLoading(0);
                        return null;
                    });
                });
            }
        },
        [validationToken]
    );

    useEffect(() => {
        var ele = document.getElementById("presentation_container");
        ele?.classList.add("removeBgImg");
        return () => {
            ele?.classList.remove("removeBgImg");
        };
    }, []);

    useEffect(() => {
        const { page, limit, sort, sortby } = fetchparams;
        fetchData(baseUrl, clientID, extraKey, localType, sortby, sort, limit, page, old_customer_id);
    }, [baseUrl, clientID, extraKey, fetchparams, fetchData, localType, old_customer_id,]);

    const deletePresentation = (id: any) => {
        if (!window.confirm(
            language?.sure_to_delete_presentation ||
            "Are you sure, you want to delete this presentation?"
        )) return null;

        const accessToken: any = validationToken(type);
        accessToken.then(({ accesskey }: any) => {
            getRemovePresentation(baseUrl, clientID, extraKey, accesskey, id).then(({ status, success }: any) => {
                if (status >= 400) {
                    setError("Bad response from server");
                    return null;
                }
                if (success) {
                    const { limit, sort, sortby } = fetchparams;
                    fetchData(baseUrl, clientID, extraKey, localType, sortby, sort, limit, 1, old_customer_id);
                    setfetchparams({ ...fetchparams, page: 1 })
                }
                return null;
            });
        });
    }

    const sortbychange = (value: any) => {
        // setSortby(value || sortByDefault);
        // setPage(1);
        localStorage.setItem("sortBy", value);
        setfetchparams({ ...fetchparams, page: 1, sortby: value || sortByDefault })
    }

    const limitchange = (value: any) => {
        // setLimit(value || limitDefault);
        // setPage(1);
        localStorage.setItem("totalLimit", value);
        setfetchparams({ ...fetchparams, page: 1, limit: value || limitDefault })
    }

    const directChange = (value: any) => {
        // setSort(value || sortDefault);
        // setPage(1);
        localStorage.setItem("sortDefault", value);
        setfetchparams({ ...fetchparams, page: 1, sort: value || sortDefault })
    }

    const onTabSwitch = (_id: any) => {
        // console.log(_id);
        const accessToken: any = validationToken(_id, true);
        accessToken.then(({ accesskey, ...resp }: any) => {
            if (resp && resp.features && resp.features?.global_menu?.length) {
                const setting: any = {};
                resp.features.global_menu.map((res: any) => {
                    const { name } = res;
                    setting[name] = res;
                    return null;
                });
                setFeatures(setting);
            }
            setType(_id);
            setfetchparams({ ...fetchparams, page: 1 })
        });
    }

    if (error) return <div>'An error has occurred: '</div>;
    const sortObj = {
        label: language !== undefined ? language.sort : "blank",
        name: "sort",
        val: fetchparams.sortby,
        sorts: dat.sorts, // props.ov.sorts,
        change: sortbychange, // props.setOV
        direction: fetchparams.sort,
        changedirection: directChange,
        stylename: "name",
        language,
    };
    const sizeObj = {
        label: language !== undefined ? language.per_page : "",
        name: "size",
        val: fetchparams.limit,
        sorts: dat.sizes,
        change: limitchange,
        direction: "reverse",
        stylename: "size",
    };
    let end_customer_quotes = "";
    if (localType) {
        lists.map((list: listType) => {
            if (list._id === localType) {
                const str = list.name;
                if (str && str.toLowerCase() === "end customer quotes")
                    end_customer_quotes = "end_customer_quotes";
                else end_customer_quotes = "";
            }
            return null;
        });
    }
    
    return (
        <div className="overview">
            <div className="overview-header">
                <div className="overview__tabs">
                    {lists.map(({ _id, name, type, title = '' }: listType) => {
                        const d = _id === localType ? "active" : "";
                        const newname = language !== undefined ? language[type] || name : name;
                        return (
                            <h3
                                key={_id}
                                className={`overview__tab ${d}`}
                                onClick={() => {
                                    if (localType !== _id) {
                                        document.title = title || 'PF Concept | Catalogue Creator - Catalogues';
                                        onTabSwitch(_id);
                                    }
                                }}
                            >
                                {newname}
                            </h3>
                        );
                    })}
                </div>

                <div className="overview__action">
                    {drawSingleSelect(sortObj)}
                    {drawSingleSelect(sizeObj)}
                </div>
            </div>

            <div className={`overview-list ${end_customer_quotes}`}>
                {/* {isLoading ? null : */}
                <div className="grid-list">
                    {datalist.map(({ _id, presentation_display_name, created_date, imgname, thumb }: datalistType) => (
                        <Item
                            key={_id}
                            name={presentation_display_name}
                            date={created_date}
                            edit={() => onEditPresentationID(_id)}
                            deleted={() => deletePresentation(_id)}
                            imgname={imgname}
                            imgtype={thumb ? thumb.type : ""}
                            imgvalue={thumb ? thumb.value : ""}
                        />
                    ))}
                </div>
                {/*  } */}
            </div>
            {total > fetchparams.limit && (
                <Pagenation limit={fetchparams.limit} total={total} page={fetchparams.page} change={(page: any) => setfetchparams({ ...fetchparams, page })} />
            )}
            {isLoading !== 0 && (
                <div className="loaderWrapper">
                    <div className="loader" />
                </div>
            )}
        </div>
    );
}
export default Overview;
